import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import auth from './../modules/auth';

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: sessionStorage
})

export default new Vuex.Store({
  state: {
    loading: false,
    message: '',
    anyo: new Date().getFullYear(),
    version: '1.0.0',

    //producción:
    baseURL: 'https://gestion.fisioterapia-rivas.com/api/',

    // desarrollo:
    // baseURL: 'http://localhost/fisioterapia-rivas/api/',

    metadatosCertificado: {},
    sellerParty: {},
    labels: {
      es: {
        labelPrevDecade: 'Década anterior',
        labelPrevYear: 'Año anterior',
        labelPrevMonth: 'Mes anterior',
        labelCurrentMonth: 'Mes actual',
        labelNextMonth: 'Mes siguiente',
        labelNextYear: 'Año siguiente',
        labelNextDecade: 'Década siguiente',
        labelToday: 'Hoy',
        labelSelected: 'Fecha seleccionada',
        labelNoDateSelected: 'Sin fecha seleccionada',
        labelCalendar: 'Calendario',
        labelNav: 'Navegación',
        labelHelp: 'Utilice las flechas para navegar por el calendario'
      }
    },
    opt_rectificacion: [
      { value: "CorrectiveDetails::METHOD_FULL", text: 'Rectificación íntegra' },
      { value: "CorrectiveDetails::METHOD_DIFFERENCES", text: 'Rectificación por diferencias' },
      { value: "CorrectiveDetails::METHOD_VOLUME_DISCOUNT", text: 'Rectificación por descuento por volumen de operaciones durante un periodo' },
      { value: "CorrectiveDetails::METHOD_AEAT_AUTHORIZED", text: 'Autorizadas por la Agencia Tributaria' },
    ],
    opt_unidades: [
      { value: "Facturae::UNIT_DEFAULT", text: 'Unidades' },
      { value: "Facturae::UNIT_TRAYS", text: 'Bandejas' },
      { value: "Facturae::UNIT_BARRELS", text: 'Barriles' },
      { value: "Facturae::UNIT_JERRICANS", text: 'Bidones' },
      { value: "Facturae::UNIT_BAGS", text: 'Bolsas' },
      { value: "Facturae::UNIT_CARBOYS", text: 'Bombonas' },
      { value: "Facturae::UNIT_BOTTLES", text: 'Botellas' },
      { value: "Facturae::UNIT_CANISTERS", text: 'Botes' },
      { value: "Facturae::UNIT_BOXES", text: 'Cajas' },
      { value: "Facturae::UNIT_CENTILITERS", text: 'Centilitros' },
      { value: "Facturae::UNIT_CENTIMITERS", text: 'Centímetros' },
      { value: "Facturae::UNIT_BINS", text: 'Cubos' },
      { value: "Facturae::UNIT_DOZENS", text: 'Docenas' },
      { value: "Facturae::UNIT_CASES", text: 'Estuches' },
      { value: "Facturae::UNIT_DEMIJOHNS", text: 'Garrafas' },
      { value: "Facturae::UNIT_GRAMS", text: 'Gramos' },
      { value: "Facturae::UNIT_HOURS", text: 'Horas' },
      { value: "Facturae::UNIT_KILOGRAMS", text: 'Kilogramos' },
      { value: "Facturae::UNIT_KILOMETERS", text: 'Kilómetros' },
      { value: "Facturae::UNIT_CANS", text: 'Latas' },
      { value: "Facturae::UNIT_LITERS", text: 'Litros' },
      { value: "Facturae::UNIT_BUNCHES", text: 'Manojos' },
      { value: "Facturae::UNIT_CUBICMETERS", text: 'Metros Cúbicos' },
      { value: "Facturae::UNIT_METERS", text: 'Metros' },
      { value: "Facturae::UNIT_MILIMETERS", text: 'Milímetros' },
      { value: "Facturae::UNIT_OTHER", text: 'Otros' },
      { value: "Facturae::UNIT_PACKAGES", text: 'Paquetes' },
      { value: "Facturae::UNIT_PORTIONS", text: 'Raciones' },
      { value: "Facturae::UNIT_ROLLS", text: 'Rollos' },
      { value: "Facturae::UNIT_SECONDS", text: 'Segundos' },
      { value: "Facturae::UNIT_ENVELOPES", text: 'Sobres' },
      { value: "Facturae::UNIT_TUBS", text: 'Tarrinas' },
      { value: "Facturae::UNIT_TETRABRIKS", text: 'Tetra Briks' },
      { value: "Facturae::UNIT_WATTS", text: 'Vatios' },
      { value: "Facturae::UNIT_6PACKS", text: '6-Packs' }
    ],
  },

  mutations: {
    setLoading(state, boolean) {
      state.loading = boolean;
    },

    setMessage(state, message) {
      state.message = message;
    },

    setAnyo(state, anyo) {
      state.anyo = anyo;
    },

    setSellerParty(state, obj) {
      state.sellerParty = obj;
    },

    setMetadatosCertificado(state, obj) {
      state.metadatosCertificado = obj;
    },

    setSign(state, value) {
      state.sellerParty.sign = value;
    },
  },

  modules: {
    auth
  },

  plugins: [vuexLocal.plugin]

})
