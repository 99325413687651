import Vue from 'vue';
import Router from 'vue-router';
import store from './../store';

Vue.use(Router)

const beforeEnter = (to, from, next) => {
  let logged = store.state.auth.logged;
  if (logged) {
    next({
      name: 'inicio'
    });
  } else {
    next();
  }
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      name: 'inicio',
      component: () => import('./../views/Inicio.vue'),
      meta: {
        requiresAuth: true,
        title: 'Inicio'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./../views/Login.vue'),
      meta: {
        title: 'Iniciar sesión'
      },
      beforeEnter: (to, from, next) => beforeEnter(to, from, next)
    },
    {
      path: '/cambiopsw',
      name: 'cambiopsw',
      component: () => import('./../views/Cambio_psw.vue'),
      meta: {
        requiresAuth: true,
        title: 'Cambiar password'
      }
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: () => import('./../views/Usuarios.vue'),
      meta: {
        requiresAuth: true,
        title: 'Gestión de usuarios'
      }
    },
    {
      path: '/pacientes',
      name: 'pacientes',
      component: () => import('./../views/Pacientes.vue'),
      meta: {
        requiresAuth: true,
        title: 'Gestión de pacientes'
      }
    },
    {
      path: '/sesiones',
      name: 'sesiones',
      component: () => import('./../views/Sesiones.vue'),
      meta: {
        requiresAuth: true,
        title: 'Gestión de sesiones'
      }
    },
    {
      path: '/historial/:id',
      name: 'historial',
      component: () => import('./../views/Historial.vue'),
      meta: {
        requiresAuth: true,
        title: 'historial médico'
      }
    },
    {
      path: '/bono/:id',
      name: 'bono',
      component: () => import('../views/Bono.vue'),
      meta: {
        requiresAuth: true,
        title: 'Bono'
      }
    },
    {
      path: '/bonos',
      name: 'bonos',
      component: () => import('../views/Bonos.vue'),
      meta: {
        requiresAuth: true,
        title: 'Bonos'
      }
    },
    {
      path: '/bonos_gastados',
      name: 'bonos_gastados',
      component: () => import('../views/BonosGastados.vue'),
      meta: {
        requiresAuth: true,
        title: 'Bonos gastados'
      }
    },
    {
      path: '/pagos',
      name: 'pagos',
      component: () => import('./../views/Pagos.vue'),
      meta: {
        requiresAuth: true,
        title: 'Pagos'
      }
    },
    {
      path: '/facturas',
      name: 'facturas',
      component: () => import('./../views/Facturas.vue'),
      meta: {
        requiresAuth: true,
        title: 'Facturación'
      }
    },
    {
      path: '/liquidacion',
      name: 'liquidacion',
      component: () => import('./../views/Liquidacion.vue'),
      meta: {
        requiresAuth: true,
        title: 'liquidación'
      }
    },
    {
      path: '/estadisticas',
      name: 'estadisticas',
      component: () => import('./../views/Estadisticas.vue'),
      meta: {
        requiresAuth: true,
        title: 'Estadísticas'
      }
    },
    {
      path: '/configuracion',
      name: 'configuracion',
      component: () => import('./../views/Configuracion.vue'),
      meta: {
        requiresAuth: true,
        title: 'Configuración inicial'
      }
    },
  ]
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const rutaProtegida = to.matched.some(record => record.meta.requiresAuth);
  let logged = store.state.auth.logged;
  if (rutaProtegida) {
    if (logged == false) {
      next({
        name: 'login'
      });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
