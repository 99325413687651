<template>
  <div class="menu">
    <!-- está logueado -->
    <template v-if="logged">
      <b-navbar toggleable="lg" type="light" variant="faded">
        <router-link :to="{name:'inicio'}">
          <b-navbar-brand><img src="@/assets/img/logotipo.png" height="90px;"></b-navbar-brand>
        </router-link>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <!-- Navbar dropdowns -->
            <b-nav-item-dropdown text="Gestión" left>
              <b-dropdown-item :to="{ name:'pacientes' }">Pacientes</b-dropdown-item>
              <b-dropdown-item :to="{ name:'sesiones' }">Sesiones</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown text="Bonos" left>
              <b-dropdown-item :to="{ name:'bonos' }">Bonos activos</b-dropdown-item>
              <b-dropdown-item :to="{ name:'bonos_gastados' }">Bonos consumidos</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown v-if="isAdmin" text="Administración" left>
              <b-dropdown-item :to="{ name:'pagos' }">Pagos</b-dropdown-item>
              <b-dropdown-item :to="{ name:'usuarios' }">Usuarios</b-dropdown-item>
              <b-dropdown-item :to="{ name:'facturas' }">Facturas</b-dropdown-item>
              <b-dropdown-item :to="{ name:'configuracion' }">Datos de configuración</b-dropdown-item>
              <b-dropdown-item :to="{ name:'estadisticas' }">Estadísticas</b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- sesión -->
            <b-nav-item-dropdown right>
              <template v-slot:button-content>
                <!-- <b-avatar size="lg" :src="gravatar"></b-avatar> -->
                <b-icon 
                  icon="person" 
                  style="width:20px; height:20px; margin-right:5px;" 
                  scale="1.3" 
                  variant="success">
                </b-icon>
                {{ usrLogin.nombre }}
              </template>
              <b-dropdown-item :to="{ name:'cambiopsw' }">Cambiar contraseña</b-dropdown-item>
              <b-dropdown-item @click="cerrarSesion">Cerrar sesión</b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- fin/sesión -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar> 
      <span class="ml-4">Gestión Fisio Sport ISKANDRIA. Versión {{ version }}</span>
    </template>

    <!-- no está logueado y/o no está verificado el email -->
    <template v-else>
      <b-navbar toggleable="lg" type="light" variant="faded">
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
              <b-nav-text>
                <span style="font-size:2rem" class="m-0">
                  <b-icon icon="person-fill" class="rounded-circle bg-danger p-1" variant="light"></b-icon>
                </span>
              </b-nav-text>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </template>
    
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';
  export default {
    name: 'Cabecera',
    data() {
      return {
        chats: [{
          id: 0,
        }],
      }
    },
    computed: {
      ...mapState('auth',['usrLogin', 'logged']),
      ...mapState(['version']),

      isAdmin: function(){
        return this.usrLogin.roll=='adm' ? true : false
      },
      gravatar(){
        return `https://www.gravatar.com/avatar/${this.usrLogin.email_md5}.jpg?s=45`;
      }
    },

    methods:{
      ...mapActions('auth', ['cerrarSesion']),
      ...mapMutations(['setLoading']),

    },
  }
</script>